.chakra-modal__content{
  padding: 10px 15px 25px 15px;
  background-color: $blue-500 !important;
  border-radius: 18px !important;
  
  .chakra-modal__header{
    background-color: $blue-500;
    color: #FFFFFF;
    padding: 10px 5px;
  }
  .chakra-modal__body{
    color: #FFFFFF;
    background-color: $blue-500;
    padding: 10px 5px;
  }

  .chakra-modal__close-btn{
    color: #FFFFFF;
  }
}
.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.emptyWarning{
  margin: 10px 0;
  color: #FFFFFF;
}

*{
  font-family: "Poppins", sans-serif !important;
  user-select: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar{
    display: none;
  }
}

body{
  transition: transform 0.5s ease-out;
  -ms-overflow-style: none;
}


/* Adicione uma classe para animar a transição */
.transition-run {
  transform: translateX(0.5px);
}

.react-datepicker-wrapper{
  position: relative;
  &:after{
    content: " ";
    //background-image: url("../../icones/icon-arrow-down.svg");
    background-repeat: no-repeat;
    mask-image: url("../../icones/icon-arrow-down.svg");
    mask-repeat: no-repeat;
    background: #4472C4;
    width: 12px;
    height: 12px;
    display: block;
    position: absolute;
    right: 10px;
    top: 9px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

.ptr__pull-down--pull-more{
  display: none !important;
}
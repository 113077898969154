.cards{

    width: 100%;
    max-width: 23rem;
    border-radius: 18px;
    padding: 20px 15px;
    margin-bottom: 15px;
    border: 1px dashed $blue-200;
    color: #FFFFFF;
    

    &.card-blue{
        background-color: #0D3D8C;
        box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    }

    h2{
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__list{
        &--item{
            list-style: none;
            padding: 5px 0;
            min-height: 42px;
            width: 100%;
            display: flex;
            border-bottom: 1px dotted $blue-300;
            align-items: center;
            &-label{
                font-size: 13px;
                font-weight: 500;
                &.sub{
                    padding-left: 20px;
                    &:before{
                        content: "-";
                        margin-right: 5px;
                    }
                }
            }
            &-value{
                margin-left: auto;
                font-size: 20px;
                &.sub{
                    font-size: 16px;
                }
            }
        }
    }
}

.card-default{
    background-color: $blue-400;
    border: 1px dashed $blue-200;
    box-shadow: $box-shadow;
    width: 100%;
    border-radius: 8px;
    display: flex;
    padding: 20px 15px;
    
}

.card-white{
    background-color: #FFFFFF;
    border: 0;
    box-shadow: $box-shadow;
    width: 100%;
    border-radius: 8px;
    display: flex;
    padding: 10px 15px;
    align-items: flex-end;

    >*{
        color: #1E327F;
    }
    .amount-value{
        color: #1E327F;
    }
}

.card-detalhes{
    border: 1px dashed $blue-200;
    background-color: $blue-400;
    box-shadow: $box-shadow;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 15px;
    color: #FFFFFF;
    padding: 10px 15px 5px 15px;

    h5{
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
    }

    ul{
        list-style: none;
        margin-bottom: 15px;
        li{
            display: flex;
            padding: 5px 0;
            justify-content: space-between;
            border-bottom: 1px dotted $blue-200;
            font-size: 14px;
            &:last-child{
                border-bottom: 0;
            }
        }
    }
}

.card-marca{
    border: 1px dashed $blue-200;
    background-color: $blue-400;
    box-shadow: $box-shadow;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 5px 15px;
    color: #FFFFFF;
    margin-right: 12px;
    height: 110px;
    margin-bottom: 15px;
    min-width: 90px;
    width: 90px;
    h4{
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 12px;
        text-align: center;
        height: 25px;
        display: flex;
        align-items: center;

        // white-space: nowrap;
        // overflow: hidden;
        // display: block;
        // text-overflow: ellipsis;
    }

    span{
        font-size: 16px;
        color: $blue-200;
        font-weight: 600;
        margin-top: auto;
    }

    figure{
        img{
            height: 25px;
            width: auto;
            max-width: 50px;
        }
        margin-bottom: 10px;
    }
}

.card-receita{
    width: 100%;
    max-width: 100px;
    border-radius: 18px;
    padding: 10px;
    margin-bottom: 0px;
    border: 1px dashed $blue-200;
    color: #FFFFFF;
    background-color: #0D3D8C;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);

    h2{
        color: $yellow;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 15px;
        font-weight: 700;
        max-width: 100%;

        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }

    ul{
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        width: 100%;
        justify-content: space-between;
        list-style: none;

        li{
            width: 100%;
            border-bottom: 1px dotted rgba(255,255,255,0.15);
            padding: 3px 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            span{
                &:last-child{
                    text-align: right;
                }
            }

            &:first-child{
                span{
                    font-weight: 700;
                    &:last-child{
                        //font-size: 14px;
                    }
                }
            }

            &:last-child{
                border-bottom: 0;
            }
        }

    }
}

.card-estoque{
    border: 1px dashed $blue-200;
    background-color: $blue-400;
    box-shadow: $box-shadow;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px 5px 15px;
    color: #FFFFFF;
    margin-right: 12px;
    //height: 110px;
    margin-bottom: 15px;
    min-width: 90px;
    width: 90px;
    h4{
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 12px;
        text-align: center;
        height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        // white-space: nowrap;
        // overflow: hidden;
        // display: block;
        // text-overflow: ellipsis;
    }

    span{
        font-size: 16px;
        color: $blue-200;
        font-weight: 600;
        margin-top: auto;
    }

    figure{
        img{
            height: 25px;
            width: auto;
            max-width: 50px;
        }
        margin-bottom: 10px;
    }
}
.accordion{
    width: 100%;
    padding: 0;
    &__item{
        border: 0;
        //border-bottom: 1px dashed #FFFFFF;
        &--title{
            border-bottom: 1px dashed #4472C4;
            &[aria-expanded=true]{
                border-bottom: 0;
            }
        }

        &--title-arrow{
            transition: all ease-in 0.2s;
        }
        button{
            padding: 0;
            justify-content: space-between;
            margin-bottom: 10px;
            &[aria-expanded=true]{
                .accordion__item--title-arrow{
                    transform: rotate(180deg);
                }
            }
        }
        &--content{
            padding: 0 0 20px 0 !important;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }

        &.active{
            
        }
    }
}
.carousel{
    width: 100%;
    overflow: hidden;
    margin-left: -5px;
    

    &.slick-slider{
        //width: calc(100% - 30px);
        .slick-list{
            .slick-track{
                display: flex;
            }
            .slick-slide{
                // margin-left: 15px;
            }
        }

        /* the slides */
    .slick-slide {
        margin: 0 7.5px;
    }

    /* the parent */
    .slick-list {
        margin: 0 -7.5px;
    }

        .slick-prev{
            //left: -35px;
        }

        .slick-next{
            //right: -25px;
            &:before{
                opacity: 1;
                content: " ";
                background-image: url("../../icones/icon-arrow-right.svg");
                width: 20px;
                height: 20px;
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                //transform: rotate(-90deg);

            }
        }
    }
}


.slider {
    width: 100%;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
      }
}

.card-marca {
    // flex-shrink: 0;
}
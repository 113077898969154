.amount-value{

    font-weight: 500;
    letter-spacing: -2px;


    &__currency,
    &__milhar{
        font-size: 16px;
        letter-spacing: -0.5px;
    }

    &__value{
        font-size: 28px;
        line-height: 28px;
        margin: 0 2px;
    }

    &.bolder{
        .amount-value__value{
            font-weight: 700;
        }
        .amount-value__currency,
        .amount-value__milhar{
            font-weight: 600;
        }
    }
}
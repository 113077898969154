.Toastify{
    &__toast-container{
        .Toastify__toast{
            &.Toastify__toast--error{
                background-color: #FB6C6C;
                .Toastify__toast-body{
                    color: #FFFFFF;
                    .Toastify__toast-icon{
                        svg{
                            fill: #FFFFFF;
                        }
                    }

                }
            }
        }
    }
}
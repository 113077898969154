.heading{
    width: 100%;
    margin-bottom: 30px;

    h1{
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        text-align: left;
    }

    h2{
        color: $yellow;
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        text-align: left;
    }

    h3{
        text-transform: uppercase;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        text-align: left;
    }

    &.heading-internal{
        h1{
            font-size: 12px;
            line-height: 12px;
        }
    }
}